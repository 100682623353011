<template>
  <svg
    :class="svgClass"
    aria-hidden="true"
  >
    <use :xlink:href="iconName" />
  </svg>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
export default defineComponent({
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const svgClass = computed(() => `svg-icon ${props.className}`)
    const iconName = computed(() => `#icon-${props.iconClass}`)
    return {
      svgClass,
      iconName
    }
  }
})
</script>

<style scoped lang="less">
.svg-icon {
  fill: currentColor;
  overflow: hidden;
}
</style>
